import camp from './camp'
import contact from './contact'
import demo_day from './demo_day'

export default {
  en: {
    camp: camp.en,
    contact: contact.en,
    demo_day: demo_day.en,
    message: {
      hello: 'hello world'
    },
    session: {
      send_verification_code: 'Send verification code',
      resend_verification_code: 'Resend verification code',
      resend_code_tips: '{seconds} s',
      phone_country: 'other',
      error_for_empty: 'Phone cannot be empty',
      error_for_not_valid: "The phone number you entered isn't valid"
    },
    contact_statistic: {
      statistic: 'Statistic',
      unit: '',
      group: 'Group',
      global: 'All',
      sort: 'Rank',
      export_current_group: 'Export current group',
      export_current_batch: 'Export current batch',
      group_search: 'Group To View',
      batch_search: 'Batch',
      cron_job_tip: 'The data is updated daily at 1:00 am',
      total_count: 'Total',
      self_total: 'Created By Self',
      latest_week_count: 'Increment In Recent Week',
      latest_month_count: 'Increment In Recent Month',
      apply_count: 'Total Apply',
      convert_rate: 'Conversion Rate',
      submitted_application_count: 'Submitted',
      application_count: 'Applications',
      summary_application_count: 'Applications Increment',
      summary_submitted_application_count: 'Submitted Applications Increment',
    },
    survey: {
      name: 'F<br>e<br>e<br>d<br>b<br>a<br>c<br>k',
      title: 'Survey',
      submit: 'Submit',
      fill_in_later: 'Fill in later',
      required: 'Please complete the data',
      tip: 'We are excited that you  registered at MiraclePlus, we\'d love to hear how we can improve.'
    }
  },
  cn: {
    camp: camp.cn,
    contact: contact.cn,
    demo_day: demo_day.cn,
    message: {
      hello: '你好世界'
    },
    session: {
      send_verification_code: '发送验证码',
      resend_verification_code: '重发验证码',
      resend_code_tips: '{seconds} 秒',
      phone_country: 'china',
      error_for_empty: '手机号不能为空',
      error_for_not_valid: '手机号码格式不正确'
    },
    contact_statistic: {
      statistic: '统计',
      unit: '条',
      group: '本组',
      global: '全体',
      sort: '排名',
      export_current_group: '导出当前组',
      export_current_batch: '导出当前批次',
      group_search: '分组查看',
      batch_search: '批次',
      cron_job_tip: '每天凌晨1点更新数据',
      total_count: '人脉总量',
      self_total: '自增总量',
      latest_week_count: '近一周人脉增量',
      latest_month_count: '近一月人脉增量',
      apply_count: '提交总量',
      convert_rate: '人脉转化率',
      submitted_application_count: '提交总量',
      application_count: '开表总量',
      summary_application_count: '开表总量',
      summary_submitted_application_count: '提交总量',
    },
    survey: {
      name: '问卷调查',
      title: '问卷调查',
      submit: '提交',
      fill_in_later: '稍后填写',
      required: '请完善数据',
      tip: '欢迎注册奇绩官网，希望占用您几分钟时间，收集一下您对奇绩的反馈。希望奇绩能在您的反馈下越做越好！'
    }
  }
}
